// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-formularz-zgloszenia-dziecka-js": () => import("./../../../src/pages/formularz-zgloszenia-dziecka.js" /* webpackChunkName: "component---src-pages-formularz-zgloszenia-dziecka-js" */),
  "component---src-pages-formularz-zgloszenia-wolontariusza-js": () => import("./../../../src/pages/formularz-zgloszenia-wolontariusza.js" /* webpackChunkName: "component---src-pages-formularz-zgloszenia-wolontariusza-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-wesprzyj-nas-js": () => import("./../../../src/pages/wesprzyj-nas.js" /* webpackChunkName: "component---src-pages-wesprzyj-nas-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-custom-js": () => import("./../../../src/templates/custom.js" /* webpackChunkName: "component---src-templates-custom-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

